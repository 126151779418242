<template>
  <v-container>
    <div class="d-flex justify-space-between align-center">
      <page-title title="訂單明細" />
      <re-order-button
        v-if="currMemberRole._isManager"
        :orderData="this.readData || {}"
        :storeId="this.storeId"
        :providerId="this.providerId"
      />
    </div>
    <customForm
      :getFormKey.sync="formKey"
      :slotComponents="slotComponents"
      :handlerSubmit="updateApi"
      :value="readData"
      :auth="auth"
      :commonProps="{ setDialog, orderConstants }"
    >
    </customForm>
    <!-- <SendComponent
      :openDialog="openDialog"
      :formKey="formKey"
      :orderConstants="orderConstants"
      :readData="readData"
      :handleSetDialog="setDialog"
      :auth="auth"
    ></SendComponent> -->
    <div class="pa-4 text-center" v-if="false">
      <v-btn
        v-if="canAdjustOrder"
        color="primary"
        outlined
        rounded
        @click="handleClickAdjustOrder"
        >調整訂單</v-btn
      >
    </div>
    <order-adjustment
      :openOrderAdjustmentDialog="openOrderAdjustmentDialog"
      :orderData="readData || {}"
      :storeId="storeId"
      :providerId="providerId"
      @updateOrderAdjustmentDialogSwitch="
        (isOpen) => (openOrderAdjustmentDialog = isOpen)
      "
      :updateData="readApi"
    />
    <PrimaryModal
      :show="openDialog"
      title="是否確認已簽收？"
      :showClose="false"
      @clickOutside="openDialog = false"
      @close="openDialog = false"
    >
      <slot></slot>
      <v-card-text v-if="auth">
        <div class="d-flex justify-center">
          <v-btn
            class="flex-grow-1 mr-4 btn01"
            outlined
            @click="openDialog = false"
            color="primary"
          >
            取消
          </v-btn>
          <custom-form-action-btn
            class="flex-grow-1 btn02"
            :action="{ key: 'submit' }"
            :form="formKey"
            label="確認"
            color="primary"
          ></custom-form-action-btn>
        </div>
      </v-card-text>
      <v-card-text v-else>未授權</v-card-text>
    </PrimaryModal>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import orderConstants from "@/modules/base/config/orderConstants";
import customForm from "@/components/form/custom/customForm.vue";
import OrderInfo from "@/modules/store/views/orderDetail/OrderInfo.vue";
import SendComponent from "@/modules/store/views/orderDetail/SendComponent.vue";
import customFormActionBtn from "@/components/form/custom/action/customFormActionBtn.vue";
import PrimaryModal from "components/modal/primaryModal.vue";
import ReOrderButton from "@/modules/store/views/orderDetail/ReOrderButton.vue";
import OrderAdjustment from "@/modules/store/views/orderDetail/OrderAdjustment";

import qs from "qs";

import { mapGetters } from "vuex";

export default {
  components: {
    customForm,
    customFormActionBtn,
    PrimaryModal,
    ReOrderButton,
    OrderAdjustment,
    // OrderButton,
    // SendComponent
  },

  data: () => ({
    readData: null,
    orderStatus: null,
    openDialog: false,
    formKey: null,
    orderConstants: orderConstants,
    openOrderAdjustmentDialog: false
  }),
  watch: {},
  async created() {
    await this.beforeFormInit();
  },
  computed: {
    ...mapGetters("member", ["currMemberRole"]),
    auth() {
      let res = true;
      if (
        typeof this.$route.query.authType !== "undefined" &&
        ["read"].includes(this.$route.query.authType)
      ) {
        res = false;
      }
      return res;
    },
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
    providerId() {
      return this.$route.params.providerId;
    },
    memberId() {
      return this.$store.getters[`token/memberId`];
    },
    canAdjustOrder() {
      return ["created", "apply"].includes(this.orderStatus);
    },

    slotComponents() {
      return [
        {
          values: ["*"],
          // excepts: ["items"],
          component: () =>
            import("@/modules/store/views/orderDetail/OrderIntro.vue"),
        },
        {
          values: ["*"],
          component: () =>
            import("@/modules/store/views/orderDetail/OrderItems2.vue"),
        },
        {
          values: ["*"],
          component: () =>
            import("@/modules/store/views/orderDetail/OrderItems3.vue"),
        },
        {
          values: ["received_record", "status", "arrival_at"],
          component: () =>
            import("@/modules/store/views/orderDetail/receiptLog.vue"),
        },
        {
          values: ["*"],
          component: () =>
            import("@/modules/store/views/orderDetail/BackRecords.vue"),
        },
      ];
    },
  },
  methods: {
    async beforeFormInit() {
      this.$store.dispatch("loading/active");
      try {
        await Promise.all([this.readApi()]);
      } catch (err) {
        console.error(err);
        this.$snotify.error("讀取錯誤");
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async readApi() {
      const res = await this.$api.collection.orderApi.read(
        this.storeId,
        this.providerId,
        this.$route.params.orderId
      );
      const res2 = await this.$api.collection.providerApi.getOrderAdjustment(
        this.providerId,
        this.$route.params.orderId
      );
      
      if (!res) {
        this.$snotify.error("", "");
      } else {
        this.readData = Object.assign(res, {adjustmentData: res2.data});
        this.orderStatus = res.status;
        if (this.$route.query.openDialog) {
          this.openDialog =
            this.$route.query.openDialog == "true" &&
            this.orderStatus == orderConstants.STATUS_SENDED
              ? true
              : false;
        }
      }
    },
    async updateApi(data) {
      this.$store.dispatch("loading/active");
      try {
        const updateOrderParams = {
          status: "STATUS_FINISHED",
        };
        let res = await this.$api.collection.orderApi.update(
          this.storeId,
          this.providerId,
          this.$route.params.orderId,
          qs.stringify(updateOrderParams)
        );

        this.readData = res;
        this.orderStatus = res.status;
        if (this.orderStatus == orderConstants.STATUS_FINISHED) {
          this.$root.gaLogEvent("商店_點擊_簽收");
          this.openDialog = false;
        }
        this.$snotify.success("變更成功");
      } catch (error) {
        this.$snotify.error("發生錯誤");
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    setDialog() {
      this.openDialog = !this.openDialog;
    },
    getFormConfig() {
      return formConfig;
    },
    handleClickAdjustOrder() {
      this.openOrderAdjustmentDialog = true;
    },
  },
};
</script>

<style lang="sass" type="text/sass" scoped></style>
